import styled from "styled-components";
import backgroundImage from "../../assets/background-mask.png";
import logo from "../../assets/logo.png";

interface IProps {
  className?: string;
}

const Home: React.FC<IProps> = ({ className }: IProps) => {
  return (
    <div className={className}>
      <section>
        <img
          src={logo}
          alt="Logomarca com a letra C bem grande baseada em designs antigos de games e escrito Camis e abaixo games"
        />
        <h1>PREPARE-SE</h1>
        <h6>Arthur Suyama e Renato Pedrosa</h6>
      </section>
    </div>
  );
};

export default styled(Home)`
  height: 100vh;
  width: 100vw;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;

  section {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    height: calc(100% - 119px);
    padding-top: 70px;
    padding-bottom: 49px;

    h1 {
      color: ${({ theme }) => theme?.color?.fontSecondary};
      font-weight: 900;
      font-size: 154px;
      text-shadow: 5px 6px 10px #00000029;
      letter-spacing: 32px;
    }

    h6 {
      font-size: 24px;
      font-weight: 300;
    }

    @media (max-width: 1250px) {
      img {
        height: 280px;
      }

      h1 {
        font-size: 70px;
      }
    }

    @media (max-width: 800px) {
      img {
        height: 280px;
      }

      h1 {
        font-size: 44px;
        letter-spacing: 8px;
        margin-top: -64px;
      }

      h6 {
        font-size: 18px;
      }
    }
  }
`;
