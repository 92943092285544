const color = {
  background: "#00AAFF",
  fontPrimary: "#FFFFFF",
  fontSecondary: "#FEDE63",
};

const font = {
  primary: `Avenir Next`,
};

export const theme = {
  color,
  font,
};
