import { createGlobalStyle } from "styled-components";
import AvenirNext from "../assets/fonts/Avenir-Next.ttc";

export default createGlobalStyle`

  @font-face {
    font-family: 'Avenir Next';
    src: local('Avenir Next'), local('AvenirNext'), url(${AvenirNext}) format('otf');
    font-weight: 300;
    font-style: normal;
  }

  * {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
  }
  body {
    height: 100vh;
    background: ${({ theme }) => theme?.color?.background};
    font: 400 16px ${({ theme }) => theme?.font?.primary};
    color: ${({ theme }) => theme?.color?.fontPrimary}
  }
`;
